import E from 'wangeditor'

// const { $, BtnMenu, DropListMenu, PanelMenu, DropList, Panel, Tooltip } = E
const { $, DropListMenu } = E

function getValue (color1, color2) {
  // background: radial-gradient(#fceeee, #df262c);
  return `
    <p style="width: 100%; padding: 4px 7px; background: radial-gradient(${color2}, ${color1}); border: 1px solid #999; color: #000; border-radius: 6px">title</p>
  `
};
class InsertText extends DropListMenu {
  constructor (editor) {
    // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
    const $elem = $(
      `<div class="w-e-menu" data-title="插入文本框">
        <button>插入</button>
      </div>`
    )
    const dropListConf = {
      width: 100,
      title: '文本框',
      type: 'list',
      list: [
        { $elem: $('<p style="color: #df262c">文本框1</p>'), value: getValue('#df262c', '#fceeee') },
        { $elem: $('<p style="color: #f68717">文本框2</p>'), value: getValue('#f68717', '#fff7e6') },
        { $elem: $('<p style="color: #fac314">文本框3</p>'), value: getValue('#fac314', '#fffaec') },
        { $elem: $('<p style="color: #169d60">文本框4</p>'), value: getValue('#169d60', '#edf7f2') },
        { $elem: $('<p style="color: #00aec7">文本框5</p>'), value: getValue('#00aec7', '#ebf9fb') },
        { $elem: $('<p style="color: #8662ac">文本框6</p>'), value: getValue('#8662ac', '#f5f3f8') }
      ],
      // droplist 每个 item 的点击事件
      clickHandler: (value) => {
        // value 参数即 dropListConf.list 中配置的 value
        this.editor.cmd.do('insertHTML', value)
      }
    }
    super($elem, editor, dropListConf)
    this.editor = editor
  }
  // 菜单点击事件
  // clickHandler () {
  //   // 做任何你想做的事情
  //   // 可参考【常用 API】文档，来操作编辑器
  //   // 在光标位置插入文字
  //   // alert('hello world')
  //   this.editor.cmd.do('insertHTML', '<p>123<p style="font-size: 22px; color: #0094ff">456</p></p>')
  // }

  // 菜单是否被激活（如果不需要，这个函数可以空着）
  // 1. 激活是什么？光标放在一段加粗、下划线的文本时，菜单栏里的 B 和 U 被激活，如下图
  // 2. 什么时候执行这个函数？每次编辑器区域的选区变化（如鼠标操作、键盘操作等），都会触发各个菜单的 tryChangeActive 函数，重新计算菜单的激活状态
  tryChangeActive () {
    // 激活菜单
    // 1. 菜单 DOM 节点会增加一个 .w-e-active 的 css class
    // 2. this.this.isActive === true
    // this.active()
    // // 取消激活菜单
    // // 1. 菜单 DOM 节点会删掉 .w-e-active
    // // 2. this.this.isActive === false
    // this.unActive()
  }
}
export const key = 'insertText'
export { InsertText }
