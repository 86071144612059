import E from 'wangeditor'
import { getElement } from '../util'
// const { $, BtnMenu, DropListMenu, PanelMenu, DropList, Panel, Tooltip } = E
const { $, PanelMenu, Panel } = E

const inputId = 'INPUTID'
const btnOkId = 'BTNOKID'

function createPanelConf (editor) {
  const tabs = [{
    title: `
      <div style="font-weight:500;">
        与前后段落间隔距离:
        <input
          id=${inputId}
          type="number"
          style="font-size: 16px;width:60px;margin:0 7px;"
          value="10"
          class="block"
          placeholder="单位:px"
        />
        px
      </div>
    `,
    tpl: `
      <div>
        <div class="w-e-button-container">
          <button id=${btnOkId} class="right">设置</button>
        </div>
      </div>
    `,
    events: [{
      selector: '#' + btnOkId,
      type: 'click',
      fn: () => {
        const $input = $('#' + inputId)
        const val = Math.abs(Number.parseFloat($input.val().trim()))
        let eleStart = editor.selection.getSelectionStartElem().elems[0]
        eleStart = getElement(eleStart, ['P', 'H1', 'H2', 'H3', 'H4', 'H5'])
        eleStart.style.marginTop = `${val}px`
        eleStart.style.marginBottom = `${val}px`
        // let eleEnd = editor.selection.getSelectionEndElem().elems[0]
        // eleStart = getElement(eleStart, 'P')
        // eleEnd = getElement(eleEnd, 'P')
        // let current = eleStart
        // while (current !== eleEnd) {
        //   current.style.marginTop = `${val}px`
        //   current.style.marginBottom = `${val}px`
        //   current = current.nextElementSibling
        // }
        // current.style.marginTop = `${val}px`
        // current.style.marginBottom = `${val}px`
      }
    }]
  }]
  const conf = {
    width: 300,
    tabs: [...tabs]
  }
  return conf
}

class LineDis extends PanelMenu {
  constructor (editor) {
    // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
    const $elem = $(
      `<div class="w-e-menu">
        <button>段距</button>
      </div>`
    )
    super($elem, editor)
    this.editor = editor
  }

  // 菜单点击事件
  clickHandler () {
    // 做任何你想做的事情
    // 可参考【常用 API】文档，来操作编辑器
    // 在光标位置插入文字
    // alert('hello world')
    // this.editor.cmd.do('insertHTML', '<p>123<p style="font-size: 22px; color: #0094ff">456</p></p>')
    // 弹出 panel
    this.createPanel()
  }

  /**
   * 创建 panel
   */
  createPanel () {
    const conf = createPanelConf(this.editor)
    const panel = new Panel(this, conf)
    panel.create()
  }

  // 菜单是否被激活（如果不需要，这个函数可以空着）
  // 1. 激活是什么？光标放在一段加粗、下划线的文本时，菜单栏里的 B 和 U 被激活，如下图
  // 2. 什么时候执行这个函数？每次编辑器区域的选区变化（如鼠标操作、键盘操作等），都会触发各个菜单的 tryChangeActive 函数，重新计算菜单的激活状态
  tryChangeActive () {
    // 激活菜单
    // 1. 菜单 DOM 节点会增加一个 .w-e-active 的 css class
    // 2. this.this.isActive === true
    // this.active()
    // // 取消激活菜单
    // // 1. 菜单 DOM 节点会删掉 .w-e-active
    // // 2. this.this.isActive === false
    // this.unActive()
  }
}
export const lineDisKey = 'lineDisKey'
export { LineDis }
