import E from 'wangeditor'
import { getElement } from '../util'
// const { $, BtnMenu, DropListMenu, PanelMenu, DropList, Panel, Tooltip } = E
const { $, DropListMenu } = E

const configs = ['0', '0.1', '0.2', '0.5', '0.8', '1', '1.15', '1.3', '1.6', '2', '2.5', '3', '4']

class LineHeight extends DropListMenu {
  constructor (editor) {
    // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
    const $elem = $(
      `<div class="w-e-menu" data-title="行高">
        <i class="w-e-icon-row-height"></i>
      </div>`
    )
    const dropListConf = {
      width: 100,
      title: '',
      type: 'list',
      list: configs.map(h => ({ $elem: $(`<p>${h}</p>`), value: h })),
      // droplist 每个 item 的点击事件
      clickHandler: (val) => {
        // val 参数即dropListConf.list 中配置的 value
        let eleStart = editor.selection.getSelectionStartElem().elems[0]
        eleStart.style.lineHeight = val
        eleStart = getElement(eleStart, ['P', 'H1', 'H2', 'H3', 'H4', 'H5'])
        eleStart.style.lineHeight = val
        // let eleStart = editor.selection.getSelectionStartElem().elems[0]
        // const eleEnd = editor.selection.getSelectionEndElem().elems[0]
        // do {
        //   const children = eleStart.children
        //   if (children.length) {
        //     eleStart = eleStart.children[0]
        //     continue
        //   } else {
        //     eleStart.style.lineHeight = val
        //     if (eleStart === eleEnd) return
        //   }
        //   const temp = eleStart
        //   eleStart = eleStart.nextElementSibling
        //   while (!eleStart) {
        //     eleStart = temp.parentElement.nextElementSibling
        //   }
        // } while (eleStart !== eleEnd)
        // eleEnd.style.lineHeight = val
      }
    }
    super($elem, editor, dropListConf)
    this.editor = editor
  }
  // 菜单点击事件
  // clickHandler () {
  //   // 做任何你想做的事情
  //   // 可参考【常用 API】文档，来操作编辑器
  //   // 在光标位置插入文字
  //   // alert('hello world')
  //   this.editor.cmd.do('insertHTML', '<p>123<p style="font-size: 22px; color: #0094ff">456</p></p>')
  // }

  // 菜单是否被激活（如果不需要，这个函数可以空着）
  // 1. 激活是什么？光标放在一段加粗、下划线的文本时，菜单栏里的 B 和 U 被激活，如下图
  // 2. 什么时候执行这个函数？每次编辑器区域的选区变化（如鼠标操作、键盘操作等），都会触发各个菜单的 tryChangeActive 函数，重新计算菜单的激活状态
  tryChangeActive () {
    // 激活菜单
    // 1. 菜单 DOM 节点会增加一个 .w-e-active 的 css class
    // 2. this.this.isActive === true
    // this.active()
    // // 取消激活菜单
    // // 1. 菜单 DOM 节点会删掉 .w-e-active
    // // 2. this.this.isActive === false
    // this.unActive()
  }
}
export const lineHeightKey = 'LineHeight'
export { LineHeight }
