<template>
  <div class="editor">
    <div ref="toolbar" class="toolbar"></div>
    <div ref="editor" class="text"></div>
  </div>
</template>

<script>
import E from 'wangeditor'
import { key, InsertText } from './addMenu/insert'
import { lineDisKey, LineDis } from './lineDis'
import { textIndentKey, TextIndent } from './textIndent'
import { LineHeight, lineHeightKey } from './lineHeight'
import { getBaseUrl } from '../../api/baseConfig'
export default {
  name: 'Wangeditor',
  data () {
    return {
      editor: null,
      info_: null
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    isClear: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isClear (val) {
      // 触发清除文本域内容
      if (val) {
        this.editor.txt.clear()
        this.info_ = null
      }
    },
    value: function (value) {
      if (value !== this.editor.txt.html()) {
        this.editor.txt.html(this.value)
      }
    }
    // value为编辑框输入的内容，这里我监听了一下值，当父组件调用得时候，如果给value赋值了，子组件将会显示父组件赋给的值
  },
  mounted () {
    this.seteditor()
    this.editor.txt.html(this.value)
  },
  methods: {
    seteditor () {
      const that = this
      this.editor = new E(this.$refs.toolbar, this.$refs.editor)
      this.editor.menus.extend(key, InsertText)
      this.editor.menus.extend(lineDisKey, LineDis)
      this.editor.menus.extend(textIndentKey, TextIndent)
      this.editor.menus.extend(lineHeightKey, LineHeight)
      this.editor.config.uploadImgShowBase64 = false // base 64 存储图片
      this.editor.config.uploadImgServer = `${getBaseUrl()}/files/upload`// 填写配置服务器端地址
      this.editor.config.uploadImgHeaders = { Authentication: this.$store.state.authentication }// 自定义 header
      this.editor.config.uploadFileName = 'file' // 后端接受上传文件的参数名
      this.editor.config.uploadImgParams = {
        fileType: 'reveal'
        // 如果版本 <=v3.1.0 ，属性值会自动进行 encode ，此处无需 encode
        // 如果版本 >=v3.1.1 ，属性值不会自动 encode ，如有需要自己手动 encode
      }
      this.editor.config.uploadImgMaxSize = 2 * 1024 * 1024 // 将图片大小限制为 2M
      this.editor.config.uploadImgMaxLength = 6 // 限制一次最多上传 6 张图片
      this.editor.config.uploadImgTimeout = 3 * 60 * 1000 // 设置超时时间
      // 自定义 onchange 触发的延迟时间，默认为 200 ms
      // this.editor.config.onchangeTimeout = 1000 // 单位 ms
      // 隐藏�网络图片�tab
      // this.editor.config.showLinkImg = false
      // 表情面板可以有多个 tab ，因此要配置成一个数组。数组每个元素代表一个 tab 的配置

      // 配置菜单
      this.editor.config.menus = [
        'head', // 标题
        'bold', // 粗体
        'fontSize', // 字号
        // 'fontName', // 字体
        lineHeightKey, // 行高
        // 'lineHeight', // 行高
        'italic', // 斜体
        'underline', // 下划线
        'strikeThrough', // 删除线
        'foreColor', // 文字颜色
        'backColor', // 背景颜色
        'link', // 插入链接
        // 'list', // 列表
        'justify', // 对齐方式
        'quote', // 引用
        'emoticon', // 表情
        'image', // 插入图片
        'table', // 表格
        // 'video', // 插入视频
        // 'code', // 插入代码
        'undo', // 撤销
        'redo', // 重复
        // 'fullscreen', // 全屏
        key, // 自定义 插入内容
        lineDisKey, // 行距
        textIndentKey // 首行缩进
      ]

      this.editor.config.uploadImgHooks = {
        fail: (xhr, editor, result) => {
          // 插入图片失败回调
          that.$message.error('插入图片失败,请重新插入图片')
        },
        success: (xhr, editor, result) => {
          that.$message.success('插入图片成功')
          // 图片上传成功回调
        },
        timeout: (xhr, editor) => {
          that.$message.error('图片上传超时')
          // 网络超时的回调
        },
        error: (xhr, editor) => {
          that.$message.error('图片上传失败,请传入2M以内的图片')
          // 图片上传错误的回调
        },
        customInsert: (insertImg, result, editor) => {
          // 图片上传成功，插入图片的回调
          // result为上传图片成功的时候返回的数据，这里我打印了一下发现后台返回的是data：[{url:"路径的形式"},...]
          // console.log(result.data[0].url)
          // insertImg()为插入图片的函数
          // 循环插入图片
          // for (let i = 0; i < 1; i++) {
          if (result.status === 'ok') {
            const url = result.content[0].absPath
            insertImg(url)
          } else {
            that.$message.error(result.msg)
          }
          // }
        }
      }
      this.editor.config.onchange = (html) => {
        this.info_ = html // 绑定当前逐渐地值
        this.$emit('change', this.info_) // 将内容同步到父组件中
      }
      this.editor.config.lineHeights = ['0', '0.1', '0.2', '0.5', '0.8', '1', '1.15', '1.3', '1.6', '2', '2.5', '3', '4']
      this.editor.config.colors = [
        '#FFFFFF',
        '#D3D3D3',
        '#FFB6C1',
        '#DA70D6',
        '#9932CC',
        '#4B0082',
        '#6A5ACD',
        '#0000CD',
        '#4169E1',
        '#B0C4DE',
        '#1E90FF',
        '#87CEEB',
        '#00FA9A',
        '#2E8B57',
        '#98FB98',
        '#8FBC8F',
        '#32CD32',
        '#00FF00',
        '#7CFC00',
        '#FFFF00',
        '#BDB76B',
        '#F0E68C',
        '#FFD700',
        '#D2B48C',
        '#FF8C00',
        '#F4A460',
        '#D2691E',
        '#FF4500',
        '#E9967A',
        '#FF6347',
        '#FF0000',
        '#696969',
        '#000000'
      ]
      // 创建富文本编辑器
      this.editor.create()
    },
    getHTML () {
      return this.editor.txt.html()
    }
  }
}
</script>

<style lang="scss" scoped>
  .editor {
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 0;
    .toolbar {
      border: 1px solid #ccc;
    }
    .text {
      border: 1px solid #ccc;
      height: 65vh;
      overflow: auto;
    }
    img{
      max-width: 50% !important;
    }
  }
</style>
